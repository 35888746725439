<div class="flex flex-col items-center md:flex-row md:space-x-8">
  <img class="rounded-full h-56 w-56 object-cover" src="../../../../assets/images/coffee-table.webp" alt="Coffee Table"/>

  <div class="w-full flex flex-col text-start space-y-4 p-8 md:p-2">
    <h1 class="text-3xl md:text-5xl font-bold">About Us</h1>
    <p class="text-xl">
      Skybeans brings the power of digital loyalty apps to your business, providing a cost-effective alternative to expensive in-house applications. It's fully customizable and easy to use, allowing you to effortlessly reward your loyal customers.
    </p>
  </div>
</div>
