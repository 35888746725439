import { Component } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-google-play-link',
  standalone: true,
  imports: [],
  templateUrl: './google-play-link.component.html',
  styleUrl: './google-play-link.component.scss'
})
export class GooglePlayLinkComponent {
  googlePlayUrl: string = environment.androidUrl;
}
