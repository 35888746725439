import { Component } from '@angular/core';
import { InViewDirective } from '../../../core/directives/in-view.directive';

@Component({
  selector: 'app-study-stats',
  standalone: true,
  imports: [InViewDirective],
  templateUrl: './study-stats.component.html',
  styleUrl: './study-stats.component.scss'
})
export class StudyStatsComponent {

}
