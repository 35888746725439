<div class="flex flex-col items-center h-dvh pb-8">
  <header class="w-full">
    <app-header
      (onLinkClicked)="scrollToSection($event)"
      (onOpenDrawer)="toggleDrawer(true)">
    </app-header>
  </header>

  <section id="banner" class="flex-1 w-full md:w-5/6 lg:w-2/3 pb-8 lg:pt-8">
    <app-banner></app-banner>
  </section>

  <section id="about" class="w-full md:pt-16 lg:w-1/2">
    <app-about></app-about>
  </section>

  <section id="stats" class="w-full md:py-16 lg:w-1/2">
    <app-study-stats></app-study-stats>
  </section>

  <section id="businesses" class="w-full lg:w-2/3">
    <app-business-info></app-business-info>
  </section>

  <section id="signup" class="w-full pt-8 md:w-5/6 lg:w-2/3">
    <app-join-now></app-join-now>
  </section>

  <div class="w-full p-8"><hr/></div>

  <section id="app" class="w-full lg:w-2/3">
    <app-app-info></app-app-info>
  </section>

  <div class="w-full p-8">
  </div>

  <footer class="w-full">
    <app-footer (onLinkClicked)="scrollToSection($event)"></app-footer>
  </footer>
</div>
