import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { InViewDirective } from '../../../core/directives/in-view.directive';
import { GooglePlayLinkComponent } from "../app-links/google-play-link/google-play-link.component";
import { AppStoreLinkComponent } from "../app-links/app-store-link/app-store-link.component";

@Component({
  selector: 'app-app-info',
  standalone: true,
  imports: [MatIconModule, MatRippleModule, InViewDirective, GooglePlayLinkComponent, AppStoreLinkComponent],
  templateUrl: './app-info.component.html',
  styleUrl: './app-info.component.scss'
})
export class AppInfoComponent {

}
