import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID, Output, EventEmitter } from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import { MatIconModule } from '@angular/material/icon';
import { BannerComponent } from '../banner/banner.component';
import { AboutComponent } from '../about/about.component';
import { JoinNowComponent } from '../join-now/join-now.component';
import { FooterComponent } from '../footer/footer.component';
import { BusinessInfoComponent } from '../business-info/business-info.component';
import { AppInfoComponent } from '../app-info/app-info.component';
import { StudyStatsComponent } from "../study-stats/study-stats.component";

@Component({
  selector: 'app-main',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    HeaderComponent,
    BannerComponent,
    AboutComponent,
    JoinNowComponent,
    FooterComponent,
    BusinessInfoComponent,
    AppInfoComponent,
    StudyStatsComponent
],
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss'
})
export class MainComponent {
  @Output() onToggleDrawer = new EventEmitter<boolean>();

  constructor(@Inject(PLATFORM_ID) private platformId: Object) { }

  scrollToSection(id: string): void {
    if (!isPlatformBrowser(this.platformId)) return;
    const targetElement = document.getElementById(id);
    if (targetElement) targetElement.scrollIntoView({behavior: 'smooth'});
  }

  toggleDrawer(isOpen: boolean): void {
    console.log('Open drawer');
    this.onToggleDrawer.emit(isOpen);
  }
}
