import { Component } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-app-store-link',
  standalone: true,
  imports: [],
  templateUrl: './app-store-link.component.html',
  styleUrl: './app-store-link.component.scss'
})
export class AppStoreLinkComponent {
  appStoreUrl: string = environment.iOSUrl;
}
