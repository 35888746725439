<div class="w-full h-full grid auto-cols-min grid-cols-12 gap-4 lg:gap-16 p-8 overflow-x-hidden">

  <div class="col-span-12 lg:col-span-8 flex flex-col space-y-8 text-start lg:text-end order-2 lg:order-1">

    <div class="flex flex-col space-y-2">
      <h1 class="text-3xl md:text-5xl font-bold">About the App</h1>
      <h2 class="text-xl">
        Download, scan, and start earning points
      </h2>
    </div>

    <div appInView class="flex flex-row lg:flex-row-reverse space-x-6 lg:space-x-reverse justify-start items-center fade-in fade-in-delay-1">
      <mat-icon class="icon-accent material-icons-round scale-150 shrink-0">phone_android</mat-icon>
      <div class="flex flex-col">
        <h3 class="text-xl font-bold">Completely free</h3>
        <span class="text-lg text-dark-muted leading-tight">Supported by thousands of devices on Android and iOS</span>
      </div>
    </div>

    <div appInView class="flex flex-row lg:flex-row-reverse space-x-6 lg:space-x-reverse justify-start items-center fade-in fade-in-delay-2">
      <mat-icon class="icon-accent material-icons-round scale-150 shrink-0">qr_code</mat-icon>
      <div class="flex flex-col">
        <h3 class="text-xl font-bold">Contactless and simple QR codes</h3>
        <span class="text-lg text-dark-muted leading-tight">Earning points is as simple as scanning a QR code, no excess permissions required</span>
      </div>
    </div>
  </div>

  <div class="col-span-12 lg:col-span-4 flex items-center justify-center lg:justify-start order-1 lg:order-2">
    <img class="border rounded-full h-64 w-64 object-cover" src="../../../../assets/images/phone.webp" alt="Phone"/>
  </div>

  <div class="col-span-12 flex items-center justify-center space-x-4 order-3">
    <br/>
    <app-google-play-link></app-google-play-link>
    <app-app-store-link></app-app-store-link>
  </div>
</div>
