<div class="relative w-full h-full flex flex-col items-center space-y-8 footer-background px-8 md:px-16 pt-8 pb-4">
  <div class="w-full h-full flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-8 items-center lg:items-start justify-center">

    <div class="h-full flex flex-1 items-center justify-center ">
      <img src="../../../../assets/images/brand/skybeans-icon-reverse.webp" class="w-28" alt="Skybeans icon reserve"/>
    </div>

    <hr class="flex lg:hidden w-full divider"/>

    <div class="flex flex-2 flex-col space-y-4 items-center lg:items-start">
      <h3 class="uppercase text-light text-xl font-bold">Contact Us</h3>

      <div class="flex flex-col lg:flex-row lg:space-x-2 items-center text-light-muted">
        <span class="text-lg">info&#64;skybeans.app</span>
      </div>

      <div class="flex flex-col lg:flex-row lg:space-x-2 items-center text-center lg:text-start text-light-muted">
        <p class="text-lg leading-tight">
          2020 N Academy Blvd, Suite 261 Unit 3407<br/>
          Colorado Springs, Colorado 80909<br/>
          United States
        </p>
      </div>
    </div>

    <hr class="flex lg:hidden w-full divider"/>

    <div class="flex flex-1 flex-col items-center lg:items-start">
      <h3 class="uppercase text-light text-xl font-bold">Resources</h3>

      <button (click)="scrollToSection('about')" class="text-lg text-light-muted">About</button>
      <button (click)="scrollToSection('businesses')" class="text-lg text-light-muted">Businesses</button>
      <button (click)="scrollToSection('app')" class="text-lg text-light-muted">App</button>
      <a [href]="['/legal/privacy-policy']" class="text-lg text-light-muted">Privacy Policy</a>
      <a [href]="['/legal/terms-of-use']" class="text-lg text-light-muted">Terms of Use</a>
      <button [routerLink]="['/faq']" class="text-lg text-light-muted">FAQ</button>
    </div>

    <hr class="flex lg:hidden w-full divider"/>

    <div class="h-full flex flex-col flex-2 items-center justify-center space-y-8 lg:items-start">
      <div class="flex space-x-8">
        <a href="https://www.instagram.com/skybeans.app/" target="_blank" aria-label="Link to instagram account">
          <img src="../../../../assets/images/social/instagram.svg" class="h-8 w-8" alt="Instagram icon"/>
        </a>
        <a href="https://x.com/skybeansapp" target="_blank" aria-label="Link to x twitter account">
          <img src="../../../../assets/images/social/x-twitter.svg" class="h-8 w-8" alt="X icon"/>
        </a>
      </div>

      <div class="flex space-x-4">
        <app-google-play-link></app-google-play-link>
        <app-app-store-link></app-app-store-link>
      </div>
    </div>
  </div>

  <span class="text-light-muted font-thin">© Skybeans {{currentYear || 2024}}</span>

  <div class="absolute bottom-4 right-1 lg:right-4 flex space-x-1 items-center">
    <span class="text-light-muted font-thin text-xs materia-icons-round">{{version}}</span>
    @if(!isProduction) {
      <mat-icon class="text-light-muted scale-75">bug_report</mat-icon>
    }
  </div>
</div>
