<div class="w-full h-full grid auto-cols-min grid-cols-12 gap-4 lg:gap-16 p-8 overflow-x-hidden">

  <div class="col-span-12 lg:col-span-4 flex flex-col items-center justify-center p-4">
    <img class="w-72 mr-16" src="../../../../assets/images/portal-screenshot-metrics.webp" alt="Portal Screenshot Metrics"/>
    <img class="w-72 ml-16 -translate-y-16" src="../../../../assets/images/portal-screenshot-setup.webp" alt="Portal Screenshot Setup"/>
  </div>

  <div class="col-span-12 lg:col-span-8 flex flex-col space-y-8 lg:text-start">
    <div class="flex flex-col space-y-2">
      <h1 class="text-3xl md:text-5xl font-bold">For Businesses</h1>
      <h2 class="text-xl">
        Skybeans offers an online portal for managing your entire rewards program in a single place
      </h2>
    </div>

    <div appInView class="flex space-x-6 items-center fade-in fade-in-delay-1">
      <mat-icon class="icon-accent material-icons-round scale-150 shrink-0">settings_suggest</mat-icon>
      <div class="flex flex-col">
        <h3 class="text-xl font-bold">Easy to set up</h3>
        <span class="text-lg text-dark-muted leading-tight">Create and customize your reward program with a guided set up process</span>
      </div>
    </div>

    <div appInView class="flex space-x-6 items-center fade-in fade-in-delay-2">
      <mat-icon class="icon-accent material-icons-round scale-150 shrink-0">account_balance</mat-icon>
      <div class="flex flex-col">
        <h3 class="text-xl font-bold">Simplified and secure billing</h3>
        <span class="text-lg text-dark-muted leading-tight">Manage your monthly subscription in a single place, no hidden costs</span>
      </div>
    </div>

    <div appInView class="flex space-x-6 items-center fade-in fade-in-delay-3">
      <mat-icon class="icon-accent material-icons-round scale-150 shrink-0">qr_code</mat-icon>
      <div class="flex flex-col">
        <h3 class="text-xl font-bold">Self service marketing material</h3>
        <span class="text-lg text-dark-muted leading-tight">Seamlessly interact with your business and rewards through custom QR codes and marketing material generated at any time</span>
      </div>
    </div>

    <div appInView class="flex space-x-6 items-center fade-in fade-in-delay-4">
      <mat-icon class="icon-accent material-icons-round scale-150 shrink-0">insights</mat-icon>
      <div class="flex flex-col">
        <h3 class="text-xl font-bold">Real-time user metrics</h3>
        <span class="text-lg text-dark-muted leading-tight">Keep track of how users are interacting with your rewards at no extra charge</span>
      </div>
    </div>
  </div>

  <div class="col-span-12 flex flex-col xl:flex-row space-y-4 xl:space-y-0 space-x-0 xl:space-x-4 items-center justify-center">
    <br/>
    <span class="text-xl">Interested in hearing more about how it all works?</span>
    <a href="mailto:info@skybeans.app?subject=Skybeans%20Demo" aria-label="Request a demo by email" target="_blank" matRipple class="flex shrink-0 items-center justify-center button-gradient-accent space-x-2 bg-white uppercase text-light rounded-full py-2 px-6">
      <span class="font-bold">Request a demo</span>
      <mat-icon>arrow_forward</mat-icon>
    </a>
  </div>
</div>
