<div class="w-full h-full flex flex-col items-center justify-center md:flex-row space-y-4 md:space-x-4 md:space-y-0 p-4 md:p-0 text-dark background-image bg-white">
  <div class="flex flex-col flex-1 h-full w-full justify-center text-center px-3 py-4 border shadow-md rounded-xl">
    <span class="icon-accent font-bold text-5xl pb-1">65%</span>
    <span>Revenue that comes from existing customers</span>
  </div>

  <div class="flex flex-col flex-1 h-full w-full justify-center text-center px-3 py-4 border shadow-md rounded-xl">
    <span class="icon-accent font-bold text-5xl pb-1">84%</span>
    <span>Customers more likely to stick with a brand that offers a loyalty program</span>
  </div>

  <div class="flex flex-col flex-1 h-full w-full justify-center text-center px-3 py-4 border shadow-md rounded-xl">
    <span class="icon-accent font-bold text-5xl pb-1">71%</span>
    <span>Customers more likely to use a loyalty program from their phone</span>
  </div>
</div>
